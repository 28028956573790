/* eslint-disable @typescript-eslint/camelcase */
import { Auth } from 'aws-amplify';

/** *******************************************************************************************************************
 *  Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

/**
 * Config parameters related to AWS Cognito & Pinpoint resources.
 */
export default {
    uiRelease: '1.1.0',
    customer: {
        name: 'Tonic'
    },
    auth: {
        identityPoolId: 'ap-southeast-2:58fe400f-9041-46a5-8b21-dddca3cc03f8',
        region: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_mLAL1uK3y',
        userPoolWebClientId: '4mfrpcr00icmh1jd20er2h54aq',
        jwtToken: async () => {
            const session = await Auth.currentSession();
            return session.idToken.jwtToken;
        },
        idToken: ''
    },
    pinPoint: {
        appId: 'c97849fde35f4a9cbb09a31d2772d06b',
        region: 'ap-southeast-2'
    },
    api: {
        endPoint: 'https://vnkzvcrgnd.execute-api.ap-southeast-2.amazonaws.com/Dev/covid-subject'
    },
    region: 'ap-southeast-2'
};
